import {
  FormSelectProps,
  SELECT_STYLES,
  SelectOption,
} from '@c/forms/controls/FormSelect';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { cx } from 'class-variance-authority';
import { Fragment } from 'react';

export default function Select({
  allowEmpty = true,
  onChange,
  value: idValue,
  options,
  placeholder = '',
  disabled,
  multiple,
  error,
  className,
}: Omit<FormSelectProps, 'control' | 'name'> & {
  value?: string | string[];
  className?: string;
}) {
  const value = multiple
    ? options.filter((o) => idValue?.includes(o.id))
    : options.find((o) => o.id === idValue);
  if (allowEmpty && !!value && !options?.some((o) => o.id === 'empty')) {
    options.unshift({ id: 'empty', label: '', value: '' });
  }
  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => (
        <div className="relative w-full">
          <Listbox.Button
            className={`${SELECT_STYLES({ error, disabled })} ${className}`}
          >
            <span className="block min-h-[2.25rem] min-w-[2.4rem] truncate pr-10">
              {multiple
                ? value
                  ? (value as SelectOption<string>[])
                      .map((v) => v.label)
                      .join(', ')
                  : placeholder
                : (value as SelectOption<string>)?.label || placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-[2rem] flex items-center pr-2">
              <ChevronDownIcon
                className="h-8 w-8 text-brand-gray"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-30 mt-2 max-h-96 w-full min-w-fit overflow-auto rounded-[.8rem] bg-brand-white py-1 text-[1.4rem] shadow-sm ring-1 ring-brand-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    cx(
                      active ? 'bg-brand-secondary-light' : '',
                      'relative flex h-10 select-none items-center pl-10 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={cx('block truncate')}>
                        {option.label}
                      </span>

                      {selected ? (
                        <span
                          className={cx(
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
