import { cx } from 'class-variance-authority';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface Mask {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  integerLimit?: number;
}

const defaultMaskOptions: Mask = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 6, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: false,
};

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputmode?: string;
  maskOptions?: Mask;
  error?: boolean;
}

const CurrencyInput = ({
  maskOptions,
  inputMode,
  disabled,
  error,
  ...inputProps
}: Props) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });
  return (
    <MaskedInput
      mask={currencyMask}
      {...inputProps}
      onFocus={(e) => e.target.select()}
      disabled={disabled}
      inputMode={inputMode ?? 'text'}
      className={cx([
        'w-full grow cursor-[inherit] border-none border-transparent bg-transparent text-input leading-input focus:outline-none',
        disabled ? 'select-none text-brand-gray' : 'text-brand-black',
        error ? 'border-red-500' : 'border-brand-gray',
      ])}
    />
  );
};

export default CurrencyInput;
