import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const styles = cva('block mb-[0.8rem] items-center font-semibold', {
  variants: {
    required: {
      true: 'after:content-["*"] after:ml-0.5 after:text-red-500',
    },
    flex: {
      true: 'flex items-center justify-between',
      default: 'block',
    },
  },
  defaultVariants: {
    required: false,
    flex: 'default',
  },
});

interface Props extends VariantProps<typeof styles> {
  value: string;
  children: React.ReactNode;
  errorMessage?: string;
  labelClasses?: string;
  flex?: boolean;
}

const FormLabel = ({
  children,
  value,
  errorMessage,
  required,
  labelClasses,
  flex,
}: Props) => {
  return (
    <div
      className={`${flex ? 'flex justify-between' : 'block'} w-full items-center text-input`}
    >
      <div className={flex ? 'flex justify-between' : ''}>
        <span className={styles({ required }) + ' ' + labelClasses}>
          {value}
        </span>
        {!!errorMessage && (
          <span className="text-[1.2rem] text-brand-red">{errorMessage}</span>
        )}
      </div>
      {children}
    </div>
  );
};

export default FormLabel;
